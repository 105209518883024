import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        ads: [],
        selectedAd: null
    },

    getters: {
        ads: state => state.ads,
        adsWithImages: state => {
            return state.ads.filter(ad => ad.images.length > 0)
        },
        selectedAd: state => state.selectedAd
    },

    mutations: {
        SET_ADS: (state, payload) => state.ads = payload,
        SELECT_AD: (state, payload) => state.selectedAd = payload
    },

    actions: {
        async fetchAds({ commit }) {
            let { data } = await axios.get('ads')

            commit('SET_ADS', data.ads)
        }
    }
}