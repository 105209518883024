import { mapActions, mapGetters } from 'vuex';

export default {
    methods: {
        ...mapActions({
            addRequest: 'requests/addRequest'
        })
    },

    computed: {
        ...mapGetters({

        })
    }
}