<template>
  <div id="guest-wrapper">
    <div style="display: none;" id="page-loading"></div>
    <div id="main-wrapper">
      <GuestTopBar />

      <GuestHeader />

      <!-- <GuestNavbar /> -->
      <div class="container-fluid" id="home-fab-wrapper">
        <button class="btn btn-primary btn-sm float-left home-fab" id="home-fab" @click.prevent="$bvModal.show('request-property-modal')">
          <span>للحجز والطلبات</span>
        </button>

        <router-link :to="{ name: 'leaseRequest' }" class="btn btn-primary btn-sm float-left home-fab" id="home-fab2">
          <span>عقد إلكتروني</span>
        </router-link>
      </div>

      <div class="container p-5">
        <router-view />
      </div>

      <div id="bottom-push"></div>
    </div>

    <GuestFooter />
  </div>
</template>

<script>
import GuestTopBar from "@/components/general/guest-top-bar";
import GuestHeader from "@/components/general/guest-header";
// import GuestNavbar from "@/components/general/guest-navbar";
import GuestFooter from "@/components/general/guest-footer";

export default {
  components: {
    GuestTopBar,
    GuestHeader,
    // GuestNavbar,
    GuestFooter,
  },
};
</script>

<style lang="scss">
@import '../assets/css/guest.scss';
</style>