<template>
  <div id="header-wrapper" class="" style="position: relative">
    <div id="header-body-wrapper">
      <div id="header-logo"></div>
    </div>
    <!-- <carousel
      id="header-slider-wrapper"
      :class="{ 'main-layout': isMain }"
      :autoplayTimeout="8000"
      :perPage="1"
      :autoplay="false"
      easing="ease-in"
      :loop="true"
      :paginationEnabled="false"
      dir="ltr"
      style="overflow: hidden;"
      :adjustableHeight="false"
    >
      <slide
        v-for="(image, i) in headerImages"
        :key="i"
        style="width: 100%"
      >
        <img
          v-lazy="image.link"
          height="auto"
          width="100%"
          style="object-fit: contain"
          alt=""
        />
      </slide>
    </carousel> -->

    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="6000"
      controls
      background="#ababab"
      :indicators="false"
      style="text-shadow: 1px 1px 2px #333; direction: rtl;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      dir="rtl"
    >
      <!-- Text slides with image -->
      <b-carousel-slide
        style="direction: rtl;"
        v-for="(image, i) in headerImages"
        :key="i"
        :img-src="image.link"
      ></b-carousel-slide>
    </b-carousel>


    <div v-if="isMain" id="properties-types-wrapper">
      <div id="properties-inner-wrapper">
        <a
          v-for="(cat, i) in categories"
          :key="i"
          class="item"
          :class="{ active: $route.params.categoryName == cat.name }"
          href="#!"
          @click.prevent="selectCategoryTap(cat)"
          >
            <p>{{ cat.name }}</p>
          </a
        >
      </div>
    </div>
  </div>
</template>

<script>
import CategoriesMixin from '@/mixins/categories'
import HeaderImagesMixin from '@/mixins/headerImages'

export default {
  mixins: [CategoriesMixin, HeaderImagesMixin],

  methods: {
    selectCategoryTap(cat) {
      this.selectCategory(cat);
      this.$router.push({ name: 'category', params: { categoryName: cat.name } })
    },
  },

  props: {
    isMain: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
