<template>
  <div>
    <div>
      <h4 class="text-center mb-4">
        <span
          style="
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px 10px;
            border-radius: 6px;
            color: white;
          "
          >حدد نوع العقد المطلوب</span
        >
      </h4>
      <!-- <b-card no-body>
        <b-tabs card>
          <b-tab title="العقد السكني">
            <b-card-text>
              <div v-if="settings" v-html="settings.lease1"></div>
            </b-card-text>
          </b-tab>

          <b-tab title="العقد التجاري">
            <b-card-text>
              <div v-if="settings" v-html="settings.lease2"></div>
            </b-card-text>
          </b-tab>

          <b-tab title="العقد بالباطن">
            <b-card-text>
              <div v-if="settings" v-html="settings.lease3"></div>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card> -->

      <div class="row">
        <div class="col-sm-6 col-md-4">
          <div
            @click="$bvModal.show('residential-lease-modal')"
            class="category-item pointer mb-4 p-5 bold"
          >
            <span>عقد سكني</span>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div
            @click="$bvModal.show('commercial-lease-modal')"
            class="category-item pointer mb-4 p-5 bold"
          >
            <span>عقد تجاري</span>
          </div>
        </div>

        <div class="col-sm-6 col-md-4">
          <div
            @click="$bvModal.show('sub-lease-modal')"
            class="category-item pointer mb-4 p-5 bold"
          >
            <span>العقد بالباطن</span>
          </div>
        </div>
      </div>
    </div>

    <ResidentialLeaseModal />
    <CommercialLeaseModal />
    <SubLeaseModal />
  </div>
</template>

<script>
import SettingsMixin from "@/mixins/settings";
import ResidentialLeaseModal from '@/components/modals/residential-lease'
import CommercialLeaseModal from '@/components/modals/commercial-lease'
import SubLeaseModal from '@/components/modals/sub-lease'

export default {
  mixins: [SettingsMixin],

  components: {
    ResidentialLeaseModal,
    CommercialLeaseModal,
    SubLeaseModal
  }
};
</script>