<template>
  <div class="container">
    <div class="property-wrapper show m-auto">
      <carousel
        :autoplayTimeout="2000"
        :controls="true"
        style="height: 500px"
        :perPage="1"
        :autoplay="true"
        easing="ease-in"
        :loop="true"
        :paginationEnabled="true"
        indicators="hover"
        dir="ltr"
        :mouseDrag="true"
      >
        <slide
          v-for="(image, i) in selectedAd.images"
          :key="i"
          style="height: 100%; width: 100%"
        >
          <img
            v-lazy="image.link"
            height="100%"
            width="100%"
            style="object-fit: contain"
            alt=""
          />
        </slide>
      </carousel>

      <h5 class="text-right bold p-3 mt-3">
        {{ selectedAd.title }}
      </h5>

      <div class="property-details-wrapper">
        <div class="property-notes text-justify">{{ selectedAd.body }}</div>

        <VideoWrapper
          v-if="selectedAd.videolink"
          class="mt-5"
          :link="selectedAd.videoLink"
          type="youtube"
        />

        <div class="map-wrapper mt-5">
          <GmapMap
          ref="map"
          :center="{lat: selectedAd.lat, lng: selectedAd.lng}"
          :zoom="14"
          style="width: 100%; height: 300px"
        >
          <GmapMarker
            :position="{ lat: selectedAd.lat, lng: selectedAd.lng }"
            :clickable="false"
            :draggable="false"
            @click="center = { lat: selectedAd.lat, lng: selectedAd.lng }"
          />
        </GmapMap>
        </div>

        <!-- <div class="map-wrapper mt-5">
          <GmapMap
          v-if="marker"
          ref="map"
          :center="{lat:marker.position.lat, lng:marker.position.lng}"
          :zoom="14"
          style="width: 100%; height: 300px"
        >
          <GmapMarker
            :position="marker.position"
            :clickable="false"
            :draggable="false"
            @click="center = marker.position"
          />
        </GmapMap>
        </div> -->

        <!-- <div class="contact-info-wrapper text-right mt-5">
          <div>المعلن: <span class="bold">محمد عبد الله</span></div>
          <div>رقم الجوال: <span class="bold">0567891011</span></div>
          <div>رقم الإعلان: <span class="bold">123456789</span></div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import AdsMixin from '@/mixins/ads'

export default {
  mixins: [AdsMixin],

  mounted () {
    if (!this.selectedAd) this.$router.push({ name: 'home' })
  }
};
</script>