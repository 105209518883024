import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2, {
    showConfirmButton: true,
    confirmButtonText: 'حسنا',
    closeButtonText: 'إغلاق',
    cancelButtonText: 'إلغاء'
});
window.swal = require('sweetalert2')

window.showSwal = function showSwal(data) {
    window.swal.fire({
        title: data.message,
        icon: data.success ? 'success' : 'error',
        confirmButtonText: 'حسنًا',
        text: data.text || null
            // confirmButtonColor: '#ec008c'
    })
}

Vue.mixin({
    methods: {
        showSwal: window.showSwal,

        showWarning(data) {
            window.swal.fire({
                title: data.message,
                icon: 'warning',
                confirmButtonText: 'حسنًا',
                // confirmButtonColor: '#ec008c'
            })
        },

        showDetailedSwal(data) {
            window.swal.fire({
                title: data.title,
                html: data.html,
                text: data.text,
                icon: data.success ? 'success' : 'error',
                // confirmButtonColor: '#ec008c'
            })
        },

        async confirmSwal() {
            return window.swal.fire({
                title: 'هل أنت متأكد؟',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'تأكيد',
                cancelButtonText: 'إلغاء',
                // confirmButtonColor: '#ec008c'
            }).then((result) => {
                if (result.value) { return true }
                return false
            });
        },

        authSwal() {
            window.swal.fire({
                title: 'فضلًا قم بتسجيل الدخول أولا',
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: 'حسنًا',
                // confirmButtonColor: '#ec008c'
            })
        },

        async showOptions(data) {
            return window.swal.fire({
                icon: 'warning',
                title: data.message,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: data.confirmText,
                cancelButtonText: `لا`,
                // confirmButtonColor: '#ec008c',
            }).then((result) => {
                if (result.isConfirmed) return true
                return false
            })
        }
    }
})