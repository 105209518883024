import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.prototype.$ = require('jquery');
window.$ = require('jquery')
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('bootstrap');



// slider
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);


// vue-lazyload
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)


// font awesome
require('./font-awesome')

// global mixin
require('./global-mixin')


// arabic numbers
import ArabicNumber from '@/components/general/arabic-number'
Vue.component('ArabicNumber', ArabicNumber)

// video component
import VideoWrapper from '@/components/general/video-wrapper'
Vue.component('VideoWrapper', VideoWrapper)


// google maps
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBQfEj4JRIhqQSyRZ2QQu5NMtIPgq-S6Ps',
        // libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    }
})


// vue-multiselect
import Multiselect from 'vue-multiselect'
Vue.component('multiselect', Multiselect)
require('vue-multiselect/dist/vue-multiselect.min.css')


// vue-sweetAlert2
require('./vue-sweetalert2')


require('@/interceptor')


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

store.dispatch('init')