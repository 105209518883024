<template>
  <b-modal id="request-property-modal" title="للحجز والطلبات" size="lg">
    <div class="container py-2">
      <div class="justify text-center mb-4">
        <span class="bold justify text-center"
          >قم بإضافة طلبك هنا للموظف المختص (إعلان عن عقار - طلب عقار - طلب إجراء عقد إلكتروني
          - شكاوى أو اقتراحات)، وسيتم الرد عليك من قبل الموظفين في أقرب
          وقت على الواتس الخاص بجوالك.</span
        >
      </div>

      <div class="form-group row">
        <label for="nameAr" class="text-muted">حدد الموظف</label>

        <multiselect
          dir="rtl"
          class="text-right"
          v-model="form.agent"
          :options="agents"
          placeholder="حدد الموظف"
          label="name"
          track-by="name"
          selectLabel="اضغظ Enter للتحديد"
          deselectLabel="اضغط Enter لإلغاء التحديد"
          autofocus
        >
          <template><span slot="noResult">لا توجد نتائج</span></template>
          <template><span slot="noOptions">لا توجد خيارات</span></template>
        </multiselect>
      </div>

      <div class="form-group row">
        <label class="text-muted">الاسم</label>
        <input type="text" class="form-control" v-model="form.name" />
      </div>

      <div class="form-group row">
        <label class="text-muted">رقم الجوال أو البريد الإلكتروني</label>
        <input type="text" class="form-control" v-model="form.contact" />
      </div>

      <div class="form-group row">
        <label class="text-muted">تفاصيل الطلب</label>
        <textarea class="form-control" v-model="form.body" rows="5"></textarea>
      </div>

      <div class="form-group">
        <label for="nameAr" class="text-muted">إرفاق صور</label>
          <ImagesWrapper
            :images="form.images"
            @images-changed="updateImages($event)"
            @delete-image="deleteImageTap($event)"
          />
      </div>
    </div>

    <template v-slot:modal-footer>
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="md"
            style="position: relative"
            @click="submit"
          >
            <i class="fa fa-check"></i>
            <span>إرسال</span>
          </b-button>
        </div>
        <button
          @click="$bvModal.hide('request-property-modal')"
          class="float-left btn btn-light"
          style="box-shadow: none"
        >
          تراجع
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import RequestsMixin from "@/mixins/requests";
import AgentsMixin from "@/mixins/agents";
import ImagesWrapper from "@/components/general/images-wrapper";

export default {
  mixins: [RequestsMixin, AgentsMixin],

  data() {
    return {
      form: {
        name: null,
        contact: null,
        body: null,
        images: []
      },
    };
  },

  mounted () {
    this.clearForm()
  },

  methods: {
    clearForm () {
      this.form = {
        name: null,
        contact: null,
        body: null,
        images: []
      }
    },

    submit() {
      if (!this.form.agent) {
        this.showSwal({
          success: false,
          message: 'فضلًا قم بتحديد الموظف المطلوب'
        })
      }
      
      this.addRequest(this.form).then((res) => {
        if (res.success) {
          this.$bvModal.hide("request-property-modal")
          this.clearForm()
        }
      });
    },

    updateImages(files) {
      files.forEach((file) => {
        file.src = URL.createObjectURL(file);
        this.form.images.push(file);
      });
    },

    deleteImageTap(image) {
      this.form.images.splice(this.form.images.indexOf(image), 1);
    },
  },

  components: {
    ImagesWrapper,
  },
};
</script>