<template>
    <div>
        <div class="row">
            <PropertyItem v-for="(property, i) in selectedCategory.properties.filter(p => p.client_id == null)" :key="i" :property="property" />
        </div>
    </div>
</template>

<script>
import PropertiesMixin from '@/mixins/properties'
import PropertyItem from '@/components/general/property-item'
import CategoriesMixin from '@/mixins/categories'

export default {
    mixins: [PropertiesMixin, CategoriesMixin],

    mounted () {
        if (!this.selectedCategory) {
            this.$router.push({ name: 'home' })
        }
    },

    components: {
        PropertyItem
    }
}
</script>