<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || "main-layout";
    },
  },

  metaInfo() {
    return {
      // meta: [
      //   { property: "og:title", content: this.hostTitle },
      //   { name: "og:title", content: this.hostTitle },
      //   { name: "keywords", content: this.hostTitle },
      // ],
      link: [
        // { rel: "stylesheet", href: `<%= VUE_APP_GUEST_URL %>/assets/css/${layout}.css` },
      ],
    };
  },
};
</script>