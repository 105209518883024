import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        records: [],
        selectedRecord: null
    },

    getters: {
        records: state => state.records,
        selectedRecord: state => state.selectedRecord
    },

    mutations: {
        SET_RECORDS: (state, payload) => state.records = payload,
        SELECT_RECORD: (state, payload) => state.selectedRecord = payload
    },

    actions: {
        async fetchRecords({ commit }) {
            let { data } = await axios.get('agents')
            commit('SET_RECORDS', data.agents)
        },
    }
}