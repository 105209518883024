import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({

        }),

        ...mapMutations({
            selectCategory: 'categories/SELECT_CATEGORY'
        })
    },

    computed: {
        ...mapGetters({
            categories: 'categories/categories',
            categoriesForFooter: 'categories/categoriesForFooter',
            selectedCategory: 'categories/selectedCategory'
        })
    }
}