import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    methods: {
        ...mapActions({

        }),

        ...mapMutations({
            selectAd: 'ads/SELECT_AD'
        })
    },

    computed: {
        ...mapGetters({
            ads: 'ads/ads',
            adsWithImages: 'ads/adsWithImages',
            selectedAd: 'ads/selectedAd'
        })
    }
}