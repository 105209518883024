<template>
  <div class="video-wrapper">
    <iframe :src="editedLink"> </iframe>
  </div>
</template>

<script>
export default {
  computed: {
    editedLink() {
      let link
      if (this.type == "youtube") {
        let url = new URL(this.link);
        let videoCode = url.searchParams.get("v");
        console.log('videocode ', videoCode)
        link = `https://www.youtube.com/embed/${videoCode}?autoplay=1&mute=1`;
      }

      return link;
    },
  },

  props: ["type", "link"],
};
</script>

<style lang="scss" scoped>
.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>