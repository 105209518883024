import axios from '@/axios'

export default {
    namespaced: true,

    state: {

    },

    getters: {

    },

    mutations: {

    },

    actions: {
        async addRequest(_, payload) {
            let formData = new FormData()
            if (payload.images) {
                for (let i = 0; i < payload.images.length; i++) {
                    formData.append('images[' + i + ']', payload.images[i])
                }
            }

            payload.images = null

            payload.agent_id = payload.agent.id
            payload.agent = null

            for (const [key, value] of Object.entries(payload)) {
                if (value !== null) {
                    formData.append(key, value)
                }
            }

            let { data } = await axios.post('requests', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            return data
        }
        // async addRequest(_, payload) {

        //     let { data } = await axios.post('requests', payload)

        //     return data
        // }
    }
}