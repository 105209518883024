import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        settings: null
    },

    getters: {
        settings: state => state.settings
    },

    mutations: {
        SET_SETTINGS: (state, payload) => state.settings = payload
    },

    actions: {
        async fetchSettings({ commit }) {
            let { data } = await axios.get('settings')
            commit('SET_SETTINGS', data.settings)
        }
    }
}