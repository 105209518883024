<template>
  <div class="container">
    <div class="property-wrapper show m-auto">
      <carousel
        v-if="selectedProperty.images.length > 0"
        :autoplayTimeout="2000"
        :controls="true"
        style="height: 500px"
        :perPage="1"
        :autoplay="true"
        easing="ease-in"
        :loop="true"
        :paginationEnabled="true"
        indicators="hover"
        dir="ltr"
        :mouseDrag="true"
      >
        <slide
          v-for="(image, i) in selectedProperty.images"
          :key="i"
          style="height: 100%; width: 100%"
        >
          <img
            v-lazy="image.link"
            height="100%"
            width="100%"
            style="object-fit: contain"
            alt=""
          />
        </slide>
      </carousel>

      <h5 class="text-right bold p-3">
        {{ selectedProperty.title }}
      </h5>

      <div class="property-details-wrapper">
        <div class="details-item row">
          <div class="col text-right">عدد الغرف</div>
          <div class="col text-right">
            <ArabicNumber :number="selectedProperty.roomsCount" />
          </div>
        </div>

        <div class="details-item row">
          <div class="col text-right">الصالة</div>
          <div class="col text-right">{{ selectedProperty.isWithHall ? 'متوفرة' : 'غير متوفرة' }}</div>
        </div>

        <div class="details-item row">
          <div class="col text-right">عدد دورات المياه</div>
          <div class="col text-right">
            <ArabicNumber :number="selectedProperty.bathsCount" />
          </div>
        </div>

        <div class="details-item row">
          <div class="col text-right">نظام المطبخ</div>
          <div class="col text-right">{{ selectedProperty.isAmericanKitchen ? 'أمريكي' : 'مستقل' }}</div>
        </div>

        <div class="details-item row">
          <div class="col text-right">توفر الأثاث</div>
          <div class="col text-right">{{ selectedProperty.isFurnished ? 'متوفر' : 'غير متوفر' }}</div>
        </div>

        <div class="details-item row">
          <div class="col text-right">السعر</div>
          <div class="col text-right">
            <ArabicNumber :number="selectedProperty.amount" />
          </div>
        </div>

        <div class="property-notes text-justify mt-5">{{ selectedProperty.body }}</div>

        <VideoWrapper
          class="mt-5"
          :link="selectedProperty.videoLink"
          type="youtube"
        />

        <div class="map-wrapper mt-5">
          <GmapMap
          v-if="marker"
          ref="map"
          :center="{lat: selectedProperty.lat, lng: selectedProperty.lng}"
          :zoom="14"
          style="width: 100%; height: 300px"
        >
          <GmapMarker
            :position="{ lat: selectedProperty.lat, lng: selectedProperty.lng }"
            :clickable="false"
            :draggable="false"
            @click="center = { lat: selectedProperty.lat, lng: selectedProperty.lng }"
          />
        </GmapMap>
        </div>

        <div class="contact-info-wrapper text-right mt-5">
          <div>المعلن: <span class="bold">{{ selectedProperty.agent ? selectedProperty.agent.name : 'الإدارة' }}</span></div>
          <!-- <div>رقم الجوال: <span class="bold">{{ selectedProperty.agent ? selectedProperty.agent.mobile : settings.mobile }}</span></div> -->
          <div>رقم العقار: <span class="bold">{{ selectedProperty.number }}</span></div>
          <p>إذا ناسبك الإعلان تواصل عن طريق الواتساب أو الاتصال على الرقم/ {{ selectedProperty.agent ? selectedProperty.agent.mobile : settings.mobile }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PropertiesMixin from '@/mixins/properties'
import SettingsMixin from '@/mixins/settings'

export default {
  mixins: [PropertiesMixin, SettingsMixin],

  computed: {
    marker () {
      return {
        position: {
          lat: 24.569378,
          lng: 46.5485403
        }
      }
    }
  }
};
</script>