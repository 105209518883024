import axios from './axios'
import store from './store'

axios.interceptors.request.use((req) => {
    store.commit('SET_IS_LOADING', true)
    return req
})

axios.interceptors.response.use((res) => {
    store.commit('SET_IS_LOADING', false)
    if (res.data.message) {
        window.showSwal(res.data)
    }
    return res
}, (error) => {
    store.commit('SET_IS_LOADING', false)
    window.showSwal({
        success: false,
        message: 'حدثت مشكلة',
        text: 'فضلًا تأكد من البيانات ثم حاول مجددًا',
    })
    throw error;
});