import axios from '@/axios'

export default {
    namespaced: true,

    state: {
        images: []
    },

    getters: {
        images: state => state.images
    },

    mutations: {
        SET_IMAGES: (state, payload) => state.images = payload
    },

    actions: {
        async fetchHeaderImages({ commit }) {
            let { data } = await axios.get('headerImages')

            commit('SET_IMAGES', data.images)
        }
    }
}