import axios from '@/axios'

export default {
    namespaced: true,

    actions: {
        async addLeaseRequest(_, payload) {
            let formData = new FormData()

            if (payload.type == 'commercial') {
                formData.append('buildingCompletionImage', payload.buildingCompletionImage)
                formData.append('crImage', payload.crImage)

                payload.buildingCompletionImage = null
                payload.crImage = null
            }
            formData.append('leaseImage', payload.leaseImage)
            payload.leaseImage = null

            payload.agent_id = payload.agent.id
            payload.agent = null


            for (const [key, value] of Object.entries(payload)) {
                if (value !== null) {
                    formData.append(key, value)
                }
            }

            let { data } = await axios.post('leaseRequests', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            })

            return data
        }
    }
}